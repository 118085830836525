import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import routes from 'hooks/api';
/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
	init() {
		this.handleAuthentication();
	}

	handleAuthentication = () => {
		const access_token = this.getAccessToken();
		const refresh_token = this.getRefreshToken();

		if (!access_token && !refresh_token) {
			this.emit('onNoAccessToken');

			return;
		}

		this.emit('onAutoLogin', true);
	};

	signInWithPhoneNumberAndPassword = (phoneNumber, password) => {
		return new Promise((resolve, reject) => {
			axios
				.post(routes.signIn, {
					phoneNumber: String(phoneNumber)?.trim(),
					password
				})
				.then(response => {
					const { access_token, refresh_token, role, phoneNumber, fullName } = response?.data;

					if (response.data) {
						this.setSession(`Bearer ${access_token}`);
						this.setRefreshToken(`Bearer ${refresh_token}`);
						this.addAccount({
							role,
							fullName,
							phone: phoneNumber,
							access_token: `Bearer ${access_token}`,
							refresh_token: `Bearer ${refresh_token}`
						});
						window.location.reload();
					} else {
						reject(response.data.error);
					}
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			axios
				.get(routes.accessToken, {
					headers: {
						common: {
							Authorization: this.getAccessToken()
						}
					}
				})
				.then(response => {
					if (response.data) {
						// const redirectUrl = process.env.REACT_APP_REDIRECT_TO_MOBILE_URL;
						// const windowWidth = window?.screen?.width;
						// const role = response?.data?.role;

						// if (windowWidth <= 500 && redirectUrl && (role === 'SALES_AGENT' || role === 'WAREHOUSE_MAN')) {
						// 	window.location.replace(redirectUrl);
						// }

						resolve(response.data);
					} else {
						this.logout();
						reject(new Error('Failed to login with token.'));
					}
				})
				.catch(() => {
					axios
						.get(routes.refreshToken, {
							headers: {
								common: {
									Authorization: this.getRefreshToken()
								}
							}
						})
						.then(response => {
							if (response.data) {
								this.setSession(`Bearer ${response.data?.access_token}`);
								this.setRefreshToken(`Bearer ${response.data?.refresh_token}`);
								this.signInWithToken()
									.then(res => resolve(res))
									.catch(() => {
										this.logout();
										reject(new Error('Failed to get user data.'));
									});
								// resolve(response.data);
							} else {
								this.logout();
								reject(new Error('Failed to login with token.'));
							}
						})
						.catch(() => {
							this.logout();
							reject(new Error('Failed to login with token.'));
						});
				});
		});
	};

	setSession = access_token => {
		if (access_token) {
			localStorage.setItem('jwt_access_token', access_token);
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
		} else {
			localStorage.removeItem('jwt_access_token');
			delete axios.defaults.headers.common.Authorization;
		}
	};

	setRefreshToken = refresh_token => {
		if (refresh_token) {
			localStorage.setItem('jwt_refresh_token', refresh_token);
		} else {
			localStorage.removeItem('jwt_refresh_token');
		}
	};

	addAccount = newAccount => {
		const accounts = JSON.parse(localStorage.getItem('accounts') || '{}');

		accounts[newAccount?.phone] = newAccount;
		localStorage.setItem('accounts', JSON.stringify(accounts));
		console.log(`Account for phone ${newAccount?.phone} has been added or updated.`);
	};

	switchAccount = phone => {
		const accounts = JSON.parse(localStorage.getItem('accounts') || '{}');
		const selectedAccount = accounts[phone];

		if (selectedAccount) {
			localStorage.setItem('activeAccount', JSON.stringify(selectedAccount));
			this.setSession(selectedAccount?.access_token);
			this.setRefreshToken(selectedAccount?.refresh_token);
			window.location.reload();
			console.log(`Switched to account: ${selectedAccount?.name}`);
		} else {
			console.log('Account not found!');
		}
	};

	removeAccount = phone => {
		const accounts = JSON.parse(localStorage.getItem('accounts') || '{}');

		if (accounts[phone]) {
			delete accounts[phone];
			localStorage.setItem('accounts', JSON.stringify(accounts));
			window.location.reload();
			console.log('Account removed successfully!');
		} else {
			console.log('Account not found!');
		}
	};

	clearAccounts = () => {
		localStorage.removeItem('accounts');
		localStorage.removeItem('activeAccount');
	};

	logout = () => {
		this.setSession(null);
		this.setRefreshToken(null);
		this.clearAccounts();
	};

	getAccessToken = () => {
		return window.localStorage.getItem('jwt_access_token');
	};

	getRefreshToken = () => {
		return window.localStorage.getItem('jwt_refresh_token');
	};
}

const instance = new JwtService();

export default instance;
