// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import App from 'app/App';
import ReactDOM from 'react-dom';
import 'rsuite/dist/rsuite.min.css';
import 'typeface-poppins';
import './i18n';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';

if (process.env.REACT_APP_ENV === 'DEV') {
	import('./utils/setBaseUrl').then(({ setBaseUrl }) => {
		window.setBaseUrl = setBaseUrl;
		console.log('setBaseUrl is now available in the console. Use it like: setBaseUrl("http://localhost:5000/api")');
	});

	import('./utils/setSocketBaseUrl').then(({ setSocketBaseUrl }) => {
		window.setSocketBaseUrl = setSocketBaseUrl;
		console.log(
			'setSocketBaseUrl is now available in the console. Use it like: setSocketBaseUrl("http://localhost:5000")'
		);
	});
}

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
