import qs from 'qs';
import { useParams } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';

const QueryHook = () => {
	const location = useLocation();
	const navigate = useHistory();
	const routeParams = useParams();
	const query = qs.parse(location.search, { ignoreQueryPrefix: true });

	const QueryParams = query;

	const stringify = (obj, params) => qs.stringify(obj, params);

	const AddQueryParams = params => {
		navigate.push(`?${qs.stringify({ ...params })}`);
	};

	const MergeQueryParams = (params, replace = false) => {
		navigate.push(`?${qs.stringify({ ...query, ...params })}`, { replace });
	};

	return { QueryParams, AddQueryParams, MergeQueryParams, stringify, navigate, location, routeParams };
};

export default QueryHook;
