import AllWarehousesAppConfig from './all-warehouses/DatasAppConfig';
import ProductsAppConfig from './products/DatasAppConfig';
import OrderRequestsAppConfig from './order-requests/DatasAppConfig';
import ReturnedProducts from './returned-products/DatasAppConfig';
import WarehouseTransferAppConfig from './warehouse-transfers/DatasAppConfig';

const WarehousesAppConfig = [
	AllWarehousesAppConfig,
	ProductsAppConfig,
	OrderRequestsAppConfig,
	ReturnedProducts,
	WarehouseTransferAppConfig
];

export default WarehousesAppConfig;
