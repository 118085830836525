import { lazy } from 'react';
import { authRoles } from 'app/auth';

const ProductHistoryAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.diller, ...authRoles.dillerHelper],
	routes: [
		{
			path: '/apps/diller/report-dealer-product-history',
			exact: true,
			component: lazy(() => import('./single/Data'))
		}
	]
};

export default ProductHistoryAppConfig;
