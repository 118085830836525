import { lazy } from 'react';
import { authRoles } from 'app/auth';

const ModeratorManagementAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.productModerator],
	routes: [
		{
			path: '/apps/request-date-management',
			exact: true,
			component: lazy(() => import('./Data'))
		},
		//Order Requests
		// {
		// 	path: '/apps/request-date-management/order-requests',
		// 	exact: true,
		// 	component: lazy(() => import('./order-requests/list/Datas'))
		// },
		{
			path: '/apps/request-date-management/order-requests/:orderId',
			exact: true,
			component: lazy(() => import('./order-requests/single/Data'))
		},
		//Transfers
		// {
		// 	path: '/apps/request-date-management/transfers',
		// 	exact: true,
		// 	component: lazy(() => import('./transfers/single/Data'))
		// },
		{
			path: '/apps/request-date-management/transfers/:groupId',
			exact: true,
			component: lazy(() => import('./transfers/single/tabs/diller-transfers-tab/transferGroup/index'))
		},

		{
			path: '/apps/request-date-management/warehouse-transfers/:transferID',
			exact: true,
			component: lazy(() =>
				import('./warehouse-transfers/single/tabs/warehouse-transfers-tab/transferGroup/index')
			)
		},
		//Returned Products
		// {
		// 	path: '/apps/request-date-management/returned-products',
		// 	exact: true,
		// 	component: lazy(() => import('./returned-products/list/Datas'))
		// },
		{
			path: '/apps/request-date-management/returned-products/:groupId',
			exact: true,
			component: lazy(() => import('./returned-products/details/Datas'))
		}
	]
};

export default ModeratorManagementAppConfig;
