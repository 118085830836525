import { createSlice } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings, setDefaultSettings } from 'app/store/fuse/settingsSlice';
import jwtService from 'app/services/jwtService';

export const setUserData = user => async (dispatch, getState) => {
	/*
        You can redirect the logged-in user to a specific route depending on his role
         */

	history.location.state = {
		redirectUrl: '/apps/dashboards/analytics' // for example 'apps/academy'
	};

	/*
    Set User Settings
     */
	const fuseSettings = localStorage.getItem('fuse-settings');
	if (fuseSettings) {
		const parsedFuseSettings = JSON.parse(fuseSettings);
		dispatch(setDefaultSettings(parsedFuseSettings));
	}

	dispatch(setUser(user));
};

export const logoutUser = () => async (dispatch, getState) => {
	const { user } = getState().auth;

	if (!user.role || user.role.length === 0) {
		// is guest
		return null;
	}

	history.push({
		pathname: '/login'
	});

	jwtService.logout();

	dispatch(setInitialSettings());

	return dispatch(userLoggedOut());
};

const initialState = {
	// role: [], // guest
	// fullName: 'John Doe',
	// image: '',
	// phoneNumber: '+998900000000',
	// shortcuts: []
	openedAddAccountModal: false
};

const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
		setUser: (state, action) => action.payload,
		userLoggedOut: (state, action) => initialState,

		// Modal state management methods
		openModal: state => {
			state.openedAddAccountModal = true;
		},
		closeModal: state => {
			state.openedAddAccountModal = false;
		},
		toggleModal: state => {
			state.openedAddAccountModal = !state.openedAddAccountModal;
		}
	},
	extraReducers: {}
});

export const { setUser, userLoggedOut, openModal, closeModal, toggleModal } = userSlice.actions;

export default userSlice.reducer;
