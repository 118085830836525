import { Box, Button, Drawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'app/auth/store/userSlice';
import Login from 'app/main/login/Login';

function AddAccount() {
	const { user } = useSelector(state => state.auth);
	const dispatch = useDispatch();

	const toggleDrawer = () => {
		dispatch(closeModal());
	};

	return (
		<Drawer open={user?.openedAddAccountModal} onClose={toggleDrawer}>
			<Box sx={{ width: '100vw', height: '100vh' }}>
				<Login isModal />
			</Box>
		</Drawer>
	);
}

export default AddAccount;
