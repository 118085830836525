import { lazy } from 'react';
import { authRoles } from 'app/auth';

const TransfersAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.warehouseMan,
	routes: [
		{
			path: '/apps/warehouse-transfers',
			exact: true,
			component: lazy(() => import('./single/Data'))
		},
		{
			path: '/apps/warehouse-transfers/:transferID',
			exact: true,
			component: lazy(() => import('./single/tabs/warehouse-transfers-tab/transferGroup/index'))
		}
	]
};

export default TransfersAppConfig;
