import { authRoles } from 'app/auth';
import { lazy } from 'react';

const OrderAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.diller, ...authRoles.dillerHelper],
	routes: [
		{
			path: '/apps/report/report-dealer-product',
			component: lazy(() => import('../product-report/ProductReport'))
		},
		{
			path: '/apps/diller/orders',
			exact: true,
			component: lazy(() => import('./single/Data'))
		},
		{
			path: '/apps/diller/order-products/:orderId',
			exact: true,
			component: lazy(() => import('./products'))
		},
		// {
		// 	path: '/apps/diller/orders/transfer/:groupId',
		// 	exact: true,
		// 	component: lazy(() => import('./single/tabs/diller-transfers-tab/transferGroup/index'))
		// },
		{
			path: '/apps/diller/orders/:id',
			exact: true,
			component: lazy(() => import('./request'))
		},
		{
			path: '/apps/diller/orders/by-moderator/:orderId',
			exact: true,
			component: lazy(() => import('./moderator-order-products'))
		},
		{
			path: '/apps/diller/orders/:orderId/:requestId',
			exact: true,
			component: lazy(() => import('./products'))
		}
	]
};

export default OrderAppConfig;
