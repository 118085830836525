import openSocket from 'socket.io-client';

const socket = openSocket(
	localStorage.getItem('socketBaseUrl') ?? process.env.REACT_APP_SOCKET_URL ?? 'http://localhost:5000',
	{
		reconnectionDelayMax: 10000,
		extraHeaders: {
			Authorization: localStorage.getItem('jwt_access_token')
		}
	}
);
export default socket;
